<template>
  <LoadingData v-if="isLoading" />
  <div v-else class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link :to="PATHS.HOME"
          ><i class="ph-caret-left"></i
        ></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-question me-2" /> Central de Ajuda
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Central de Ajuda</h6>
          <p>
            Aqui são listados os boletos em aberto com a SN, é possível copiar o
            código de barras e também realizar o download dos boletos em PDF.
          </p>
        </template>
      </Popper>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="card card-body bg-warning text-white">
            <div class="d-flex align-items-center">
              <i class="ph-wrench ph-2x opacity-75 me-3"></i>

              <div class="flex-fill text-end">
                <h4 class="mb-0">Garantia</h4>
                <router-link
                  class="text-white"
                  :to="PATHS.SUPPORT_NEW_TICKET_WARRANTIES"
                  >Acessar</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card card-body bg-dark text-white">
            <div class="d-flex align-items-center">
              <i class="ph-question ph-2x opacity-75 me-3"></i>

              <div class="flex-fill text-end">
                <h4 class="mb-0">Abrir Chamado</h4>
                <router-link class="text-white" :to="PATHS.SUPPORT_TICKET_NEW"
                  >Acessar</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 col-md-4">
          <div class="card card-body bg-info text-white">
            <div class="d-flex align-items-center">
              <i class="ph-chats-circle ph-2x opacity-75 me-3"></i>

              <div class="flex-fill text-end">
                <h4 class="mb-0">FAQ</h4>
                <router-link class="text-white" :to="PATHS.SUPPORT_FAQ"
                  >Acessar</router-link
                >
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div>
      <h4 class="text-center fw-light">Atendimentos</h4>
      <div class="card-body">
        <input
          type="search"
          class="form-control rounded-pill"
          placeholder="Buscar"
          v-model="search"
        />
      </div>
      <LoadingData v-if="isLoadingTickets" />
      <div v-else class="table-responsive mb-3">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th>Protocolo</th>
              <th>Assunto</th>
              <th>Status</th>
              <th>Data</th>
              <th>Detalhar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ticket in tickets" :key="ticket.ticketUuid">
              <td>{{ ticket.ticketProtocol }}</td>
              <td>{{ ticket.ticketSubjectName }}</td>
              <td>{{ ticket.ticketStatusName }}</td>
              <td>{{ dateTimeSqlToDateTimeBR(ticket.createdAt) }}</td>
              <td class="p-1">
                <router-link
                  class="btn btn-sm btn-secondary rounded-pill"
                  :to="`/supports/tickets/${ticket.ticketUuid}`"
                >
                  Detalhar
                </router-link>
              </td>
            </tr>
            <tr v-if="tickets.length === 0">
              <td class="text-center" colspan="5">
                Nenhum atendimento encontrado
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'
import Popper from 'vue3-popper'
import LoadingData from '@/components/commons/LoadingData.vue'
import { dateTimeSqlToDateTimeBR } from '@/utils/date'

export default {
  name: 'SupportHome',
  components: {
    LoadingData,
    Popper,
  },
  data() {
    return {
      PATHS,
      isLoading: false,
      unity: this.$store.getters.unity,
      isLoadingTickets: true,
      tickets: [],
      search: '',
      dateTimeSqlToDateTimeBR,
    }
  },
  methods: {
    async getTicketsList() {
      try {
        this.isLoadingTickets = true
        this.tickets = await this.$store.dispatch('getTickets', {
          search: this.search,
        })
        this.isLoadingTickets = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  watch: {
    search() {
      this.getTicketsList()
    },
  },
  mounted() {
    this.getTicketsList()
  },
}
</script>
