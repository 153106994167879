<template>
  <div class="">
    <p>Selecione uma opção:</p>
    <div
      class="form-check mb-2"
      v-for="(option, index) in objectOptions.options"
      :key="index"
    >
      <input
        type="radio"
        class="form-check-input"
        :name="`option_interaction_${interaction.tiId}`"
        :id="`option_interaction_${interaction.tiId}_${index}`"
        :value="option"
        v-model="selectedOption"
        :disabled="!isEditable"
      />
      <label
        class="form-check-label"
        :for="`option_interaction_${interaction.tiId}_${index}`"
        >{{ option }}</label
      >
    </div>
    <div v-if="isEditable" class="d-flex align-items-center text-center">
      <button
        type="button"
        class="btn btn-primary"
        @click="sendOption"
        :disabled="!selectedOption || disableButton"
      >
        Selecionar Opção
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InteractionsOption',

  props: {
    interaction: {
      type: Object,
      required: true,
    },
    options: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      uuid: this.$route.params.uuid,
      objectOptions: null,
      selectedOption: null,
      isEditable: true,
      disableButton: false,
    }
  },
  methods: {
    async sendOption() {
      try {
        const option = this.objectOptions
        option.selected = this.selectedOption

        this.disableButton = true
        // eslint-disable-next-line no-await-in-loop
        const response = await this.$store.dispatch(
          'selectOptionInteractionTicket',
          {
            uuid: this.uuid,
            id: this.interaction.tiId,
            data: option.selected,
          },
        )

        if (response.status) {
          this.$root.$refs.notification.success(response.message)
          this.message = ''
          this.files = []
          this.showFileInput = false
          this.$emit('getInteractions')
        }
        this.disableButton = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },
  },
  beforeMount() {
    this.objectOptions = JSON.parse(this.options)
    this.selectedOption = this.objectOptions.selected
    this.isEditable = !this.objectOptions.selected
  },
  mounted() {},
}
</script>
