<template>
  <div
    class="modal fade"
    tabindex="-1"
    id="modalRegisterMaintenance"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Adicionar Registro de Manutenção</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('closeModal', false)"
          ></button>
        </div>
        <form @submit.prevent="registerMaintenance">
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="form-group mb-2">
                  <label>
                    Nº de Registro do Profissional:
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': v$.maintenance.registerNumber.$error,
                    }"
                    v-model="v$.maintenance.registerNumber.$model"
                  />
                  <span
                    v-if="!v$.maintenance.registerNumber.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="form-group mb-2">
                  <label> Observações: </label>
                  <textarea
                    class="form-control"
                    v-model="maintenance.observation"
                  ></textarea>
                </div>
              </div>
              <div class="col-12 mb-2">
                <label for="newDocumentFile" class=""
                  >Comprovante: <span class="text-danger">*</span></label
                >
                <input
                  type="file"
                  required
                  @change="onFilePicked"
                  class="form-control"
                  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                  id="newDocumentFile"
                  placeholder="Nome de Identificação do Documento"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link text-primary"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Cadastrar"
              :disabled="v$.$invalid || disableButton || !maintenance.file"
            >
              <span v-if="disableButton">
                <i class="ph-spin fa-spin"></i>
              </span>
              <span v-else>Adicionar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'

export default {
  name: 'RegisterMaintenanceModal',
  props: {
    selectedMaintenance: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      maintenance: {
        registerNumber: {
          required,
        },
      },
    }
  },
  data() {
    return {
      modalInstance: null,

      maintenance: {
        constructiveSubSystemId: '',
        uuidUnity: '',
        expectedDate: '',
        registerNumber: '',
        observation: '',
        file: '',
      },
      disableButton: false,
    }
  },
  methods: {
    openModal() {
      this.modalInstance = new window.bootstrap.Modal(
        document.getElementById('modalRegisterMaintenance'),
      )
      this.modalInstance.show()
    },
    onFilePicked(event) {
      const { files } = event.target
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      const [fileuploaded] = files
      this.maintenance.file = fileuploaded
    },
    async registerMaintenance() {
      const formData = new FormData()
      formData.append(
        'constructiveSubSystemId',
        this.selectedMaintenance.constructiveSubSystemId,
      )
      formData.append('uuidUnity', this.maintenance.uuidUnity)
      formData.append('expectedDate', this.maintenance.expectedDate)
      formData.append('registerNumber', this.maintenance.registerNumber)
      formData.append('observation', this.maintenance.observation)
      formData.append('file', this.maintenance.file)
      try {
        this.response = await this.$store.dispatch(
          'registerPreventiveMaintenance',
          formData,
        )
        this.$root.$refs.notification.success(
          'Registro de manutenção cadastrado com sucesso.',
        )
        this.modalInstance.hide()
        this.$emit('closeModal', true)
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  beforeMount() {
    this.maintenance = this.selectedMaintenance
  },
  mounted() {
    this.openModal()
  },
}
</script>
