<template>
  <div class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link :to="PATHS.SUPPORT"
          ><i class="ph-caret-left"></i
        ></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-question me-2" /> Novo Atendimento
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Central de Ajuda</h6>
          <p>Permite que você abra um chamado sobre diversos assuntos</p>
        </template>
      </Popper>
    </div>
    <div class="card-body">
      <form @submit.prevent="openTicket">
        <div class="row">
          <div class="col-12 mb-2">
            <label for="subject">Selecione o assunto:</label>
            <select
              :disabled="isLoadingSubjects"
              id="subject"
              class="form-select"
              :class="{ 'is-invalid': v$.warrantySubjectCode.$errors.length }"
              v-model="v$.warrantySubjectCode.$model"
              @change="verifySubjects()"
            >
              <option value="" selected disabled>Selecione um item</option>
              <option
                v-for="subject in subjects"
                :key="subject.ticketSubjectId"
                :value="subject.ticketSubjectId"
              >
                {{ subject.ticketSubjectName }}
              </option>
            </select>
            <span
              v-if="v$.warrantySubjectCode.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
          </div>
          <div class="col-12 mb-2">
            <label for="message">Descreva sua solicitação:</label>
            <textarea
              id="message"
              class="form-control"
              :class="{ 'is-invalid': v$.message.$errors.length }"
              v-model="v$.message.$model"
            >
            </textarea>
            <span v-if="v$.message.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="v$.message.minLength.$invalid" class="invalid-feedback">
              O campo deve possuir pelo menos 30 caracteres
            </span>
          </div>

          <div class="col-12 mb-2">
            <label for="warrantyLocalId">Anexo:</label>
            <input
              type="file"
              id="attachment"
              @change="onFilePicked"
              class="form-control"
              v-on="attachment"
            />
          </div>
          <div class="col-12">
            <div class="d-flex justify-content-between">
              <div>
                <div v-if="!isLoadingWarrantyStatus">
                  <p v-html="warrantyStatusMessage"></p>
                </div>
              </div>
              <button
                class="btn btn-primary rounded-pill"
                :disabled="v$.$invalid || disableButton"
              >
                <span v-if="disableButton">
                  <i class="ph-spinner"></i> Registrando ...
                </span>
                <span v-else>Cadastrar</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import Popper from 'vue3-popper'
import PATHS from '@/router/routesMap'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'NewTicketGeneral',
  components: {
    Popper,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      warrantySubjectCode: {
        required,
      },
      message: {
        required,
        minLength: minLength(30),
      },
    }
  },
  data() {
    return {
      PATHS,
      disableButton: false,
      isLoadingSubjects: true,
      unity: this.$store.getters.unity,
      subjects: [],
      warrantySubjectCode: null,
      message: '',
      attachment: null,
      file: null,
    }
  },
  methods: {
    async getSubjects() {
      try {
        this.subjects = await this.$store.dispatch('getSubjects')
        this.isLoadingSubjects = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    verifySubjects() {
      if (this.warrantySubjectCode === 'G') {
        this.$router.push({
          name: 'NewTicketWarranties',
        })
      }
    },
    onFilePicked(event) {
      const { files } = event.target
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      const [fileuploaded] = files
      this.file = fileuploaded
    },
    async openTicket() {
      try {
        this.disableButton = true
        const formData = new FormData()
        formData.append('codPessEmpr', this.unity.codPessEmpr)
        formData.append('numUnity', this.unity.numUnity)
        formData.append('numSubUnity', this.unity.numSubUnity)
        formData.append('message', this.message)
        formData.append('warrantySubjectCode', this.warrantySubjectCode)
        formData.append('file', this.file)
        const response = await this.$store.dispatch('openTicket', formData)
        this.$root.$refs.notification.success(response.message)
        this.$router.push({
          name: 'TicketDetails',
          params: { uuid: response.data },
        })
        this.disableButton = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getSubjects()
  },
}
</script>
