<template>
  <div>
    <div class="card">
      <div
        class="card-header d-flex align-items-center justify-content-between py-0"
      >
        <div>
          <router-link to="/news/constructions"
            ><i class="ph-caret-left"></i
          ></router-link>
        </div>
        <h6 class="py-3 mb-0 text-secondary">
          <i class="ph-files me-2" /> Por Dentro da Obra
        </h6>
        <Popper>
          <button type="button" class="btn btn-link">
            <i class="ph-info ms-2"></i>
          </button>
          <template #content>
            <h6>Boletos em Aberto</h6>
            <p>
              Aqui são listados os boletos em aberto com a SN, é possível copiar
              o código de barras e também realizar o download dos boletos em
              PDF.
            </p>
          </template>
        </Popper>
      </div>
      <LoadingData v-if="isLoadingConstruction" />
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center fw-light mb-0">
              {{ construction.month }}/{{ construction.year }}
            </h3>
            <h6 class="text-center fw-light mb-0">
              {{ construction.description }}
            </h6>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            v-for="(img, index) in imgs"
            :key="index"
            class="pic col-6 col-md-4 col-lg-2"
            @click="() => showImg(index)"
          >
            <img :src="img.src" class="img-fluid" />
            <div>
              <span
                ><small>{{ img.title }}</small></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <vue-easy-lightbox
          :visible="visibleRef"
          :imgs="imgs"
          :index="indexRef"
          @hide="onHide"
        ></vue-easy-lightbox>
      </div>
    </div>
  </div>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min'
import LoadingData from '@/components/commons/LoadingData.vue'
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'

export default {
  name: 'NewsConstructionsDetails',

  components: {
    LoadingData,
    VueEasyLightbox,
  },

  data() {
    return {
      unity: this.$store.getters.unity,
      isLoadingConstruction: true,
      uuid: this.$route.params.uuid,
      construction: {},
      isSlide: false,
      imgs: [],
      visibleRef: false,
      indexRef: 0,
    }
  },

  methods: {
    onHide() {
      this.indexRef = 0
      this.visibleRef = false
    },
    showImg(index) {
      this.indexRef = index
      this.visibleRef = true
    },
    generateImageArray() {
      this.imgs = []
      this.construction.photos.forEach((image) => {
        this.imgs.push({
          src: `${this.construction.path}/${image.filename}`,
          title: image.description,
        })
      })
    },
    async getConstructionData() {
      try {
        this.isLoadingConstruction = true
        this.construction = await this.$store.dispatch(
          'getConstructionData',
          this.uuid,
        )
        this.generateImageArray()
        this.isLoadingConstruction = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getConstructionData()
  },
}
</script>
