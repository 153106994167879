<template>
  <div>
    <div class="card">
      <div
        class="card-header d-flex align-items-center justify-content-between py-0"
      >
        <div>
          <router-link to="/news"><i class="ph-caret-left"></i></router-link>
        </div>
        <h6 class="py-3 mb-0 text-secondary">
          <i class="ph-files me-2" /> Por Dentro da Obra
        </h6>
        <Popper>
          <button type="button" class="btn btn-link">
            <i class="ph-info ms-2"></i>
          </button>
          <template #content>
            <h6>Boletos em Aberto</h6>
            <p>Exibe imagens com as evoluções mensais do empreendimento</p>
          </template>
        </Popper>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div
                class="col-12 col-md-3"
                v-for="construction in constructions"
                :key="construction.ncUuid"
              >
                <router-link :to="`/news/constructions/${construction.ncUuid}`">
                  <div class="card bg-primary text-white">
                    <div class="card-body">
                      <h6 class="fw-light text-center mb-0">
                        {{ construction.month }}/{{ construction.year }}
                      </h6>
                    </div>
                  </div>
                </router-link>
              </div>
              <div v-if="isLoadingConstruction" class="col-12">
                <LoadingData />
              </div>
              <div v-else class="col-12 col-md-3">
                <button class="card border-primary" @click="getConstructions()">
                  <div class="card-body">
                    <h6 class="fw-light text-center mb-0 text-primary">
                      Carregar Mais
                    </h6>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'NewsConstructionsList',

  components: {
    LoadingData,
  },

  data() {
    return {
      unity: this.$store.getters.unity,
      isLoadingConstruction: true,
      constructions: [],
      limit: 10,
      offset: 0,
    }
  },

  methods: {
    async getConstructions() {
      try {
        this.isLoadingConstruction = true
        const data = await this.$store.dispatch('getConstruction', {
          unity: this.unity.codPessEmpr,
          limit: this.limit,
          offset: this.offset,
        })

        this.offset += this.limit
        this.constructions.push(...data)
        this.isLoadingConstruction = false

        if (data.length === 0) {
          this.$root.$refs.notification.warning(
            'Não há mais registros publicados.',
          )
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getConstructions()
  },
}
</script>
