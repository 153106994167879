<template>
  <div>
    <div class="card">
      <div
        class="card-header d-flex align-items-center justify-content-between py-0"
      >
        <div>
          <router-link to="/news/informatives"
            ><i class="ph-caret-left"></i
          ></router-link>
        </div>
        <h6 class="py-3 mb-0 text-secondary">
          <i class="ph-files me-2" /> Informativos
        </h6>
        <Popper>
          <button type="button" class="btn btn-link">
            <i class="ph-info ms-2"></i>
          </button>
          <template #content>
            <h6>Boletos em Aberto</h6>
            <p>
              Aqui são listados os boletos em aberto com a SN, é possível copiar
              o código de barras e também realizar o download dos boletos em
              PDF.
            </p>
          </template>
        </Popper>
      </div>
      <LoadingData v-if="isLoadingInformative" />
      <div v-else>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h3 class="text-center fw-light mb-0">
                {{ informative.title }}
              </h3>
              <p class="">
                {{ informative.description }}
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              v-for="(img, index) in imgs"
              :key="index"
              class="pic col-6 col-md-4 col-lg-2"
              @click="() => showImg(index)"
            >
              <img :src="img.src" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <vue-easy-lightbox
            :visible="visibleRef"
            :imgs="imgs"
            :index="indexRef"
            @hide="onHide"
          ></vue-easy-lightbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import Popper from 'vue3-popper'
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min'
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'

export default {
  name: 'NewsInformativeDetails',

  components: {
    LoadingData,
    Popper,
    VueEasyLightbox,
  },

  data() {
    return {
      isLoadingInformative: true,
      uuid: this.$route.params.uuid,
      informative: {},
      isSlide: false,
      imgs: [],
      visibleRef: false,
      indexRef: 0,
    }
  },

  methods: {
    onHide() {
      this.indexRef = 0
      this.visibleRef = false
    },
    showImg(index) {
      this.indexRef = index
      this.visibleRef = true
    },
    generateImageArray() {
      this.imgs = []
      this.informative.photos.forEach((image) => {
        this.imgs.push({
          src: `${this.informative.path}/${image.filename}`,
          title: image.description,
        })
      })
    },
    async getInformativeData() {
      const { user } = this.$store.getters.user
      try {
        this.isLoadingInformative = true
        this.informative = await this.$store.dispatch(
          'getInformativeData',
          this.uuid,
        )
        this.generateImageArray()
        if (this.informative.changeUnity) {
          this.$root.$refs.notification.info(
            'Alterado a unidade para visualização do informativo.',
          )
        }
        this.isLoadingInformative = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getInformativeData()
  },
}
</script>
